<template>
    <main>
        <HeaderTab :title="$t('menu.setting.shortcuts')" />
        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="box mt-5">
                            <div>
                                <label>{{ $t('menu.setting.shortcuts') }}</label>
                                <div
                                    v-for="(shortcut, index) in shortcuts"
                                    :key="shortcut.shortcuts_id"
                                >
                                    <b-form-checkbox
                                        class="col-12"
                                        name="check-button"
                                        switch 
                                        v-model="shortcut.shortcuts_personal"
                                        @change="clear_route(shortcut.shortcuts_id)"
                                    >
                                        {{ $t('shortcuts.personal_shortcut') }}
                                    </b-form-checkbox>
                                    <div class="form-group form-inline col-12">
                                        <div class="col" v-if="!shortcut.shortcuts_personal">
                                            <e-select
                                                v-model="shortcut.menu"
                                                id="id"
                                                track-by="name"
                                                label="name"
                                                :placeholder="$t('shortcuts.select_menu')"
                                                :selectedLabel="$t('select.selectedLabel')"
                                                :options="menus"
                                                :searchable="true"
                                                :allow-empty="false"
                                                :show-labels="false"
                                                @select="set_shortcut($event, shortcut.shortcuts_id)"
                                            >
                                                <template slot="first" slot-scope="{ option }">{{ option.label }}</template>
                                                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                                            </e-select>
                                        </div>
                                        <input v-else type="text" :id="shortcut.shortcuts_id" name="url" class="form-control mx-3 col" v-model="shortcuts[index].shortcuts_route" placeholder="">
                                        <input type="text" :id="shortcut.shortcuts_id" name="key" class="form-control col-md-2" v-model="shortcuts[index].shortcuts_key" placeholder="" @focus="add_listener(shortcut.shortcuts_id)" @blur="remove_listener" readonly>
                                        <button class="btn btn-secondary col-md-1" @click.prevent="delete_shortcut(shortcut.shortcuts_keycode)"><font-awesome-icon :icon="['fal', 'trash']"/></button>
                                    </div>
                                    <hr>
                                </div>
                                <div class="form-group form-inline">
                                    <button class="btn btn-primary" @click.prevent="add_shortcut">{{ $t('global.ajouter') }} <font-awesome-icon :icon="['fal', 'plus']"/></button>
                                </div>
                                <div class="text-center">
                                    <button class="btn btn-primary rounded-pill" @click='checkForm'><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t('global.enregistrer') }} <font-awesome-icon :icon="['fal', 'save']" class="ml-1" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</main>
</template>

<script type="text/javascript">
    import Common from '@/assets/js/common.js'
    import ShortcutsMixin from '@/mixins/Shortcuts';

    export default {
        name: "Shortcuts",
        mixins: [ShortcutsMixin],
        data () {
            return {
                shortcuts: [],
                deleted_shortcuts: [],
                menus: [],
                menu: null,
                processing: false,
                current_shortcut_id: null
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.menus = Common.getPrimaryMenus()
                const shortcuts = await this.getShortcuts()
                this.shortcuts = this.format_shortcuts(shortcuts)

                this.setConfig('shortcuts', this.shortcuts)
            },

            set_shortcut(event, shortcuts_id = null) {
                let id = this.current_shortcut_id
                if(shortcuts_id) {
                    id = shortcuts_id
                }

                let current_shortcut = this.shortcuts.find(s => {
                    return s.shortcuts_id == id
                })

                if(current_shortcut) {
                    if(event && event.id && event.name) {
                        current_shortcut.shortcuts_route = event.id
                    }
                    else if(current_shortcut.menu && current_shortcut.menu.id) {
                        current_shortcut.shortcuts_route = current_shortcut.menu.id
                    }

                    if(event.keyCode) {
                        current_shortcut.shortcuts_keycode = event.keyCode
                    }

                    if(event.key) {
                        let key = ''
                        current_shortcut.shortcuts_ctrl = false
                        current_shortcut.shortcuts_meta = false
                        current_shortcut.shortcuts_alt = false
                        current_shortcut.shortcuts_shift = false

                        if(event.ctrlKey) {
                            key = key + 'Ctrl'
                            current_shortcut.shortcuts_ctrl = true
                        }
                        if(event.metaKey) {
                            if(key) {
                                key = key + ' + '
                            }
                            key = key + 'Cmd'
                            current_shortcut.shortcuts_meta = true
                        }
                        if(event.altKey) {
                            if(key) {
                                key = key + ' + '
                            }
                            key = key + 'Alt'
                            current_shortcut.shortcuts_alt = true
                        }
                        if(event.shiftKey) {
                            if(key) {
                                key = key + ' + '
                            }
                            key = key + 'Shift'
                            current_shortcut.shortcuts_shift = true
                        }

                        if(event.key == 'Meta' || event.key == 'Shift' || event.key == 'Control' || event.key == 'Alt') {
                            current_shortcut.shortcuts_key = event.key
                        }
                        else if(key) {
                            current_shortcut.shortcuts_key = key + ' + ' + event.key
                        }
                        else {
                            current_shortcut.shortcuts_key = event.key
                        }
                    }
                }
            },

            async add_shortcut() {
                this.shortcuts.push({
                    shortcuts_id: Common.getNegativeId(),
                    shortcuts_key: '',
                    shortcuts_route: '',
                    shortcuts_keycode: '',
                    shortcuts_menu: '',
                    shortcuts_personal: false,
                    shortcuts_ctrl: false,
                    shortcuts_meta: false,
                    shortcuts_shift: false,
                    shortcuts_alt: false
                })
            },

            delete_shortcut(key) {
                const index = this.shortcuts.findIndex(elem => key == elem.shortcuts_keycode)
                this.deleted_shortcuts.push(key)
                this.shortcuts.splice(index, 1)
            },

            checkForm() {
                this.processing = true
                this.saveShortcuts(this.shortcuts)
                .then(() => {
                    this.processing = false
                    this.init_component()
                })
            },

            format_shortcuts(shortcuts) {
                if(!shortcuts) {
					return []
				}

				for (var i = 0; i < shortcuts.length; i++) {
                    if(!shortcuts[i].shortcuts_personal && shortcuts[i].shortcuts_route) {
                        shortcuts[i].menu = [{
                            id: shortcuts[i].shortcuts_route,
                            name: this.getTrad('shortcuts.' + shortcuts[i].shortcuts_route)
                        }]
                    }

                    let key = ''
                    if(shortcuts[i].shortcuts_ctrl) {
                        key = key + 'Ctrl'
                    }
                    if(shortcuts[i].shortcuts_meta) {
                        if(key) {
                            key = key + ' + '
                        }
                        key = key + 'Cmd'
                    }
                    if(shortcuts[i].shortcuts_alt) {
                        if(key) {
                            key = key + ' + '
                        }
                        key = key + 'Alt'
                    }
                    if(shortcuts[i].shortcuts_shift) {
                        if(key) {
                            key = key + ' + '
                        }
                        key = key + 'Shift'
                    }

                    if(key) {
                        shortcuts[i].shortcuts_key = key + ' + ' + String.fromCharCode(shortcuts[i].shortcuts_keycode)
                    }
                    else if(shortcuts[i].shortcuts_keycode > 111 && shortcuts[i].shortcuts_keycode < 125) {
                        switch(shortcuts[i].shortcuts_keycode) {
                            case 112:
                                shortcuts[i].shortcuts_key = 'F1'
                                break;
                            case 113:
                                shortcuts[i].shortcuts_key = 'F2'
                                break;
                            case 114:
                                shortcuts[i].shortcuts_key = 'F3'
                                break;
                            case 115:
                                shortcuts[i].shortcuts_key = 'F4'
                                break;
                            case 116:
                                shortcuts[i].shortcuts_key = 'F5'
                                break;
                            case 117:
                                shortcuts[i].shortcuts_key = 'F6'
                                break;
                            case 118:
                                shortcuts[i].shortcuts_key = 'F7'
                                break;
                            case 119:
                                shortcuts[i].shortcuts_key = 'F8'
                                break;
                            case 120:
                                shortcuts[i].shortcuts_key = 'F9'
                                break;
                            case 121:
                                shortcuts[i].shortcuts_key = 'F10'
                                break;
                            case 122:
                                shortcuts[i].shortcuts_key = 'F11'
                                break;
                            case 123:
                                shortcuts[i].shortcuts_key = 'F12'
                                break;
                            case 124:
                                shortcuts[i].shortcuts_key = 'F13'
                                break;
                        }
                    }
                    else {
                        shortcuts[i].shortcuts_key = String.fromCharCode(shortcuts[i].shortcuts_keycode)
                    }
				}

				return shortcuts
            },

            clear_route(shortcuts_id) {
                let current_shortcut = this.shortcuts.find(s => {
                    return s.shortcuts_id == shortcuts_id
                })

                current_shortcut.shortcuts_route = ''
            },

            add_listener(shortcuts_id) {
                this.current_shortcut_id = shortcuts_id
                window.addEventListener('keydown', this.set_shortcut, true)
            },

            remove_listener() {
                window.removeEventListener('keydown', this.set_shortcut, true)
                this.current_shortcut_id = null
            }
        },
        components: {
            HeaderTab: () => import('@/components/HeaderTab'),
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
        }
    };
</script>